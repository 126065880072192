import SvgColor from "../../components/svg-color";
import Central from "../../../../../Assets/central.svg";
import Oferte from "../../../../../Assets/oferte.svg";
import News from "../../../../../Assets/news.svg";
import Products from "../../../../../Assets/products.svg";
import About from "../../../../../Assets/about.svg";
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Central",
    path: "",
    icon: <SvgColor src={Central} sx={{ width: 1, height: 1 }} />,
  },
  {
    title: "Oferte",
    path: "/offers",
    icon: <SvgColor src={Oferte} sx={{ width: 1, height: 1 }} />,
  },

  {
    title: "Anunțuri",
    path: "/news",
    icon: <SvgColor src={News} sx={{ width: 1, height: 1 }} />,
  },
  {
    title: "Produse",
    path: "/products",
    icon: <SvgColor src={Products} sx={{ width: 1, height: 1 }} />,
  },
  {
    title: "Descriere",
    path: "/description",
    icon: <SvgColor src={About} sx={{ width: 1, height: 1 }} />,
  },
];

export default navConfig;
