import { m } from "framer-motion";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import BrandAw from "../../../Assets/awerness.png";
import Fidelizare from "../../../Assets/fidelizare.jpeg";
import NewClients from "../../../Assets/loy.png";
import Spin from "../../../Assets/spin.png";
import Statistici from "../../../Assets/statistic.png";
import Search from "../../../Assets/search.png";
import Cheap from "../../../Assets/cheap.png";

import { varFade, MotionViewport } from "../../Manage/src/components/animate";
import Logo from "../../Manage/src/components/logo";

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: BrandAw,
    title: "Constientizarea Afacerii",
    description:
      "Cu MyOffer vei fi listat la o baza mare de utilizatori care isi doresc ca gaseasca cele mai bune preturi. Afacerea ta va fi vizibila pentru toti utilizatorii care se afla in proximitatea ta",
  },
  {
    icon: Fidelizare,
    title: "Fidelizarea clientiilor",
    description:
      "Convinge-ti clinetii sa se aboneze la tine, pentru a primi: anunturile, codurile personalizate de reducere si a fi recomandat pentru cautarile de produse",
  },
  {
    icon: NewClients,
    title: "Gaseste clineti noi",
    description:
      "Detinem multe unelte prin care te poti face cat mai vizibil pentru clienti noi. Una dintre ele este ruleta reducerilor, care va da la utilizatori ai aplicatiei ofertele tale",
  },

  {
    icon: Statistici,
    title: "Statistici",
    description:
      "Iti vom elabora cele mai bune si detaliate stastici despre magazinul tau. Uite cateva: cel mai cautat produs, numarul de reduceri folosite/nefolosite, numarul de abonati, etc.",
  },
  {
    icon: Search,
    title: "Produse vizibile",
    description:
      "Prin functia noastra de cautare, clientii pot sa iti gaseasca produsele, aratandu-le pretul si distanta fata de tine. Mai ales daca ai produse mai specifice te va ajuta mult această funcție",
  },
  {
    icon: Cheap,
    title: "Metoda de marketing eficienta",
    description:
      "Aceasta metoda de marketing este eficienta, si nu este costisitoare. Poti sa faci tot ce fac aplicatiile brand-urilor mari, la pret minim.",
  },
];

// ----------------------------------------------------------------------

export default function HomeMinimal() {
  return (
    <Container
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          textAlign: "center",
          mb: { xs: 5, md: 10 },
        }}
      >
        <m.div variants={varFade().inDown}>
          <Typography variant="h2">
            Cum te ajuta <br /> MyOffer?
          </Typography>
        </m.div>
      </Stack>

      <Box
        gap={{ xs: 3, lg: 10 }}
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        {CARDS.map((card, index) => (
          <m.div variants={varFade().inUp} key={card.title}>
            <Card
              sx={{
                textAlign: "center",
                boxShadow: { md: "none" },
                bgcolor: "background.default",
                p: (theme) => theme.spacing(10, 5),
                ...((index === 1 || index === 3 || index === 5) && {
                  boxShadow: (theme) => ({
                    md: `-40px 40px 80px ${
                      theme.palette.mode === "light"
                        ? alpha(theme.palette.grey[500], 0.16)
                        : alpha(theme.palette.common.black, 0.4)
                    }`,
                  }),
                }),
              }}
            >
              <Box
                component="img"
                src={card.icon}
                alt={card.title}
                sx={{ mx: "auto", width: 300, height: 200 }}
              />

              <Typography variant="h5" sx={{ mt: 8, mb: 2 }}>
                {card.title}
              </Typography>

              <Typography sx={{ color: "text.secondary" }}>
                {card.description}
              </Typography>
            </Card>
          </m.div>
        ))}
      </Box>
    </Container>
  );
}
