import { m, useScroll } from "framer-motion";
import { useRef, useState, useEffect, useCallback } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Cupons from "../../../Assets/cupons.png";
import { useNavigate } from "react-router-dom";
import Desktop from "../../../Assets/desktop.png";
import Telefon from "../../../Assets/telefon.png";

import {
  bgBlur,
  bgGradient,
  textGradient,
} from "../../../Components/Manage/src/theme/css";

import Iconify from "../../Manage/src/components/iconify";
import { varFade } from "../../Manage/src/components/animate";
import Logo from "../../Manage/src/components/logo";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(
      theme.palette.background.default,
      theme.palette.mode === "light" ? 0.9 : 0.94
    ),
    imgUrl: "/assets/background/overlay_3.jpg",
  }),
  width: "100%",
  height: "100vh",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    top: 0,
    left: 0,
    position: "fixed",
  },
}));

const StyledWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
}));

const StyledTextGradient = styled(m.h1)(({ theme }) => ({
  ...textGradient(`300deg, orange 5%, red 40%, red 50%, red 75%, red 100%`),
  padding: 0,
  marginTop: 8,
  lineHeight: 1,
  fontWeight: 900,
  marginBottom: 24,
  letterSpacing: 8,
  textAlign: "center",
  backgroundSize: "400%",
  fontSize: `${64 / 16}rem`,
  fontFamily: theme.typography.fontSecondaryFamily,
  [theme.breakpoints.up("md")]: {
    fontSize: `${96 / 16}rem`,
  },
}));

const StyledEllipseTop = styled("div")(({ theme }) => ({
  top: -80,
  width: 480,
  right: -80,
  height: 480,
  borderRadius: "50%",
  position: "absolute",
  filter: "blur(100px)",
  WebkitFilter: "blur(100px)",
}));

const StyledEllipseBottom = styled("div")(({ theme }) => ({
  height: 400,
  bottom: -200,
  left: "10%",
  right: "10%",
  borderRadius: "50%",
  position: "absolute",
  filter: "blur(100px)",
  WebkitFilter: "blur(100px)",
}));

const StyledPolygon = styled("div")(
  ({ opacity = 1, anchor = "left", theme }) => ({
    ...bgBlur({
      opacity,
      color: theme.palette.background.default,
    }),
    zIndex: 9,
    bottom: 0,
    height: 80,
    width: "50%",
    position: "absolute",
    clipPath: "polygon(0% 0%, 100% 100%, 0% 100%)",
    ...(anchor === "left" && {
      left: 0,
      ...(theme.direction === "rtl" && {
        transform: "scale(-1, 1)",
      }),
    }),
    ...(anchor === "right" && {
      right: 0,
      transform: "scaleX(-1)",
      ...(theme.direction === "rtl" && {
        transform: "scaleX(1)",
      }),
    }),
  })
);

// ----------------------------------------------------------------------

export default function HomeHero() {
  const theme = useTheme();

  const heroRef = useRef(null);

  const { scrollY } = useScroll();

  const navigate = useNavigate();
  const [percent, setPercent] = useState(0);

  const lightMode = theme.palette.mode === "light";

  const getScroll = useCallback(() => {
    let heroHeight = 0;

    if (heroRef.current) {
      heroHeight = heroRef.current.offsetHeight;
    }

    scrollY.on("change", (scrollHeight) => {
      const scrollPercent = (scrollHeight * 100) / heroHeight;

      setPercent(Math.floor(scrollPercent));
    });
  }, [scrollY]);

  useEffect(() => {
    getScroll();
  }, [getScroll]);

  const transition = {
    repeatType: "loop",
    ease: "linear",
    duration: 60 * 4,
    repeat: Infinity,
  };

  const opacity = 1 - percent / 100;

  const hide = percent > 120;

  const renderDescription = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        marginTop: "20px",
        mx: "auto",
        maxWidth: 480,
        opacity: opacity > 0 ? opacity : 0,
      }}
    >
      <m.div variants={varFade().in}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
          }}
        >
          Dezvoltă afacerea cu <br />
        </Typography>
      </m.div>

      <m.div variants={varFade().in}>
        <StyledTextGradient
          animate={{ backgroundPosition: "200% center" }}
          transition={{
            repeatType: "reverse",
            ease: "linear",
            duration: 20,
            repeat: Infinity,
          }}
        >
          MyOffer
        </StyledTextGradient>
      </m.div>

      <m.div variants={varFade().in}>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Cea mai diferită platformă de cupoane din Romanânia. Aplicația care
          unește clineții cu comerciantii. Ajută la recunoastere <br />
          brand-ului si ofera afacerilor uneltele necesare pentru a ține
          clienții conectați
        </Typography>
      </m.div>

      <m.div variants={varFade().in}>
        <Stack
          spacing={0.75}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 3 }}
        >
          {/* <Rating readOnly value={4.95} precision={0.1} max={5} />
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            <Box component="strong" sx={{ mr: 0.5, color: "text.primary" }}>
              4.96/5
            </Box>
            (99+ reviews)
          </Typography> */}
        </Stack>
      </m.div>

      <m.div variants={varFade().in}>
        <Stack
          spacing={1.5}
          direction={{ xs: "column-reverse", sm: "row" }}
          sx={{ mb: 5 }}
        >
          <Stack alignItems="center" spacing={2}>
            <Button
              color="inherit"
              size="large"
              variant="contained"
              startIcon={<Iconify icon="eva:flash-fill" width={24} />}
              onClick={() => {
                navigate("/register");
              }}
            >
              Pre-înscriere
            </Button>

            <Link
              color="inherit"
              variant="caption"
              target="_blank"
              onClick={() => {
                window.open("https://my-offer.ro/termeni.pdf", "_blank");
                window.open(
                  "https://my-offer.ro/confidentialitate.pdf",
                  "_blank"
                );
              }}
              rel="noopener"
              sx={{
                textDecoration: "underline",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Iconify
                icon="eva:external-link-fill"
                width={16}
                sx={{ mr: 0.5 }}
              />
              Termenii si confidențialitate
            </Link>
          </Stack>

          <Button
            color="inherit"
            size="large"
            variant="outlined"
            target="_blank"
            rel="noopener"
            sx={{ borderColor: "text.primary" }}
            style={{ height: "45px" }}
          >
            <a href="#why" style={{ textDecoration: "none", color: "inherit" }}>
              De ce MyOffer
            </a>
          </Button>
        </Stack>
      </m.div>
    </Stack>
  );

  const renderSlides = (
    <div className="hero-images">
      <img
        src={Telefon}
        style={{
          width: "280px",
          height: "550px",
          position: "absolute",
          top: "80px",
          zIndex: "4",
          left: "1150px",
        }}
      />
      <img
        src={Cupons}
        style={{
          width: "480px",
          height: "350px",
          position: "absolute",
          top: "500px",
          zIndex: "3",
          transform: "rotate(10deg)",
          left: "1050px",
        }}
      />
    </div>
    // <Stack
    //   direction="row"
    //   alignItems="flex-start"
    //   sx={{
    //     height: "150%",
    //     position: "absolute",
    //     opacity: opacity > 0 ? opacity : 0,
    //     transform: `skew(${-16 - percent / 24}deg, ${4 - percent / 16}deg)`,
    //     ...(theme.direction === "rtl" && {
    //       transform: `skew(${16 + percent / 24}deg, ${4 + percent / 16}deg)`,
    //     }),
    //   }}
    // >
    //   <Stack
    //     component={m.div}
    //     variants={varFade().in}
    //     sx={{
    //       width: 344,
    //       position: "relative",
    //     }}
    //   >
    //     <Box
    //       component={m.img}
    //       animate={{ y: ["0%", "100%"] }}
    //       transition={transition}
    //       alt={lightMode ? "light_1" : "dark_1"}
    //       src={
    //         lightMode
    //           ? `/assets/images/home/hero/light_1.webp`
    //           : `/assets/images/home/hero/dark_1.webp`
    //       }
    //       sx={{ position: "absolute", mt: -5 }}
    //     />
    //     <Box
    //       component={m.img}
    //       animate={{ y: ["-100%", "0%"] }}
    //       transition={transition}
    //       alt={lightMode ? "light_1" : "dark_1"}
    //       src={
    //         lightMode
    //           ? `/assets/images/home/hero/light_1.webp`
    //           : `/assets/images/home/hero/dark_1.webp`
    //       }
    //       sx={{ position: "absolute" }}
    //     />
    //   </Stack>

    //   <Stack
    //     component={m.div}
    //     variants={varFade().in}
    //     sx={{ width: 720, position: "relative", ml: -5 }}
    //   >
    //     <Box
    //       component={m.img}
    //       animate={{ y: ["100%", "0%"] }}
    //       transition={transition}
    //       alt={lightMode ? "light_2" : "dark_2"}
    //       src={
    //         lightMode
    //           ? `/assets/images/home/hero/light_2.webp`
    //           : `/assets/images/home/hero/dark_2.webp`
    //       }
    //       sx={{ position: "absolute", mt: -5 }}
    //     />
    //     <Box
    //       component={m.img}
    //       animate={{ y: ["0%", "-100%"] }}
    //       transition={transition}
    //       alt={lightMode ? "light_2" : "dark_2"}
    //       src={
    //         lightMode
    //           ? `/assets/images/home/hero/light_2.webp`
    //           : `/assets/images/home/hero/dark_2.webp`
    //       }
    //       sx={{ position: "absolute" }}
    //     />
    //   </Stack>
    // </Stack>
  );

  const renderPolygons = (
    <>
      <StyledPolygon />
      <StyledPolygon anchor="right" opacity={0.48} />
      <StyledPolygon
        anchor="right"
        opacity={0.48}
        sx={{ height: 48, zIndex: 10 }}
      />
      <StyledPolygon anchor="right" sx={{ zIndex: 11, height: 24 }} />
    </>
  );

  const renderEllipses = (
    <>
      <StyledEllipseTop />
      <StyledEllipseBottom />
    </>
  );
  console.log(window.innerWidth);
  return (
    <>
      <StyledRoot
        ref={heroRef}
        style={{}}
        sx={{
          ...(hide && {
            opacity: 0,
          }),
        }}
      >
        <div style={{ backgroundColor: "#f9fafa", height: "100vh" }}>
          {window.innerWidth < 750 ? (
            <div
              style={{
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Logo />
            </div>
          ) : (
            <Logo
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}
          {window.innerWidth > 750 && (
            <Container sx={{ height: 1 }} style={{}}>
              <Grid container columnSpacing={{ md: 10 }} sx={{ height: 1 }}>
                <Grid>{renderDescription}</Grid>
              </Grid>
            </Container>
          )}
          {window.innerWidth <= 750 && (
            <Container>
              <Grid>
                <Grid>{renderDescription}</Grid>
              </Grid>
            </Container>
          )}
        </div>
        {window.innerWidth > 750 && renderSlides}
      </StyledRoot>

      <Box sx={{ height: { md: "100vh" } }} />
    </>
  );
}
