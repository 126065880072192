import { m } from "framer-motion";
import { useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tab from "@mui/material/Tab";
import Chip from "@mui/material/Chip";
import Tabs from "@mui/material/Tabs";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Alert from "@mui/material/Alert";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import Statisticii from "../../../Assets/statisticii.png";
import Users from "../../../Assets/users.png";
import Products from "../../../Assets/products.png";
import Avatar from "@mui/material/Avatar";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Notification from "../../../Assets/notification.svg";
import Clicks from "../../../Assets/click.svg";
import Oferte from "../../../Assets/oferte.svg";
import Wait from "../../../Assets/wait.svg";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import AlertTitle from "@mui/material/AlertTitle";
import AvatarGroup from "@mui/material/AvatarGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AppCurrentVisits from "../../Manage/src/sections/overview/app-current-visits";
import AppWebsiteVisits from "../../Manage/src/sections/overview/app-website-visits";
import AppWidgetSummary from "../../Manage/src/sections/overview/app-widget-summary";
import AppTrafficBySite from "../../Manage/src/sections/overview/app-traffic-by-site";
import AppCurrentSubject from "../../Manage/src/sections/overview/app-current-subject";
import AppConversionRates from "../../Manage/src/sections/overview/app-conversion-rates";

import Label from "../../Manage/src/components/label";
import Image from "../../Manage/src/components/image";
import Iconify from "../../Manage/src/components/iconify";
import { varFade, MotionViewport } from "../../Manage/src/components/animate";

// ----------------------------------------------------------------------

export default function HomeHugePackElements() {
  const [slider, setSlider] = useState(24);

  const [select, setSelect] = useState("Option 1");

  const [app, setApp] = useState("chat");

  const [rating, setRating] = useState(2);

  const [currentTab, setCurrentTab] = useState("Angular");

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleChangeSelect = useCallback((event) => {
    setSelect(event.target.value);
  }, []);

  const viewAllBtn = (
    <m.div variants={varFade().inUp}>
      <Button
        size="large"
        color="inherit"
        variant="outlined"
        target="_blank"
        rel="noopener"
        endIcon={
          <Iconify
            icon="eva:arrow-ios-forward-fill"
            width={18}
            sx={{ ml: -0.5 }}
          />
        }
      >
        View All Components
      </Button>
    </m.div>
  );

  const renderDescription = (
    <Stack
      sx={{
        textAlign: { xs: "center", md: "unset" },
        pl: { md: 5 },
        pt: { md: 15 },
      }}
    >
      <m.div variants={varFade().inUp}>
        <Typography
          component="div"
          variant="overline"
          sx={{ color: "text.disabled" }}
        >
          MyOffer
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ my: 3 }}>
          Unelte superioare <br />
          pentru marketing
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: "text.secondary",
          }}
        >
          Am creat cele mai revolutionare unelte de marketing pentru tine.
        </Typography>
      </m.div>
    </Stack>
  );

  const renderContent = (
    <Stack
      component={Paper}
      variant="outlined"
      alignItems="center"
      spacing={{ xs: 3, md: 5 }}
      sx={{
        borderRadius: 2,
        bgcolor: "unset",
        borderStyle: "dashed",
        p: { xs: 3, md: 5 },
      }}
    >
      {/* Row 1 */}

      {/* Row 2 */}
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 3, md: 4 }}
        sx={{ width: 1 }}
      >
        <m.div variants={varFade().in}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              boxShadow: (theme) =>
                `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {["Produse", "Anunturi", "Oferte"].map((tab) => (
              <Tab
                key={tab}
                value={tab}
                label={tab}
                sx={{
                  "&:not(:last-of-type)": { mr: 3 },
                }}
              />
            ))}
          </Tabs>
        </m.div>
      </Stack>

      {/* Row 4 */}
      <Stack
        spacing={{ xs: 12, md: 12 }}
        sx={{
          width: 1,
          gap: 3,
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
        }}
      >
        <m.div variants={varFade().in}>
          <Alert severity="success" onClose={() => {}}>
            <AlertTitle>Success</AlertTitle>
            Oferta ta a fost <strong>postata</strong>
          </Alert>
        </m.div>
      </Stack>

      <img src={Statisticii} style={{ width: "100%" }} />
      <img src={Products} style={{ width: "100%" }} />
      <img src={Users} style={{ width: "100%", height: "50%" }} />
    </Stack>
  );

  return (
    <Container
      sx={{
        py: { xs: 10, md: 15 },
      }}
      style={{ backgroundColor: "#f9fafa" }}
    >
      <Grid
        container
        direction={{ xs: "column", md: "row-reverse" }}
        spacing={5}
      >
        <Grid xs={12} md={5}>
          {renderDescription}
        </Grid>

        <Grid xs={12} md={7}>
          {renderContent}
        </Grid>
      </Grid>
    </Container>
  );
}
