import { updateDoc, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from "./firebase";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  deleteObject,
  listAll,
} from "firebase/storage";
import Promise from "bluebird";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorSession,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneInfoOptions,
} from "firebase/auth";

// Used in multi-factor enrollment.
let verificationId = null;
let multiFactorResolver = null;
export const getMfaResolver = (error, auth) => {
  multiFactorResolver = getMultiFactorResolver(auth, error);
  return multiFactorResolver;
};

// Starts MFA enrollment and request the verification code.

export const uploadFile = () => {};

export const updateDocDatabase = async (
  collection,
  documentName,
  dataToUpdate
) => {
  try {
    const document = doc(db, collection, documentName);
    const docSnap = await getDoc(document);

    if (docSnap.exists()) {
      await updateDoc(document, { ...dataToUpdate });
    } else {
      await setDoc(document, { ...dataToUpdate });
    }
  } catch (err) {
    return { type: "error", message: err.message };
  }
  return;
};

export const getDataDoc = (collection, documentName) =>
  new Promise(async (resolve) => {
    try {
      const document = doc(db, collection, documentName);
      let snap = await getDoc(document);
      if (snap) {
        let dataSnap = snap.data();

        resolve(dataSnap);
      }
    } catch (err) {
      return { type: "error", message: err.message };
    }
    return;
  });

export const uploadFileDatabse = async (files, collection) => {
  return new Promise(async (resolve, reject) => {
    let newArray = [];
    for (let file of files) {
      const storageRef = ref(storage, collection + "/" + file.name);
      await uploadBytes(storageRef, file)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        })
        .then((dowloadURL) => {
          newArray.push(dowloadURL);
        });
    }
    resolve(newArray);
  });
  return [];
};

export const deleteDataDoc = async (collection, documentName) => {
  try {
    const document = doc(db, collection, documentName);

    await deleteDoc(document);
  } catch (err) {
    return { type: "error", message: err.message };
  }
  return;
};

const download = (url) => {
  return fetch(url).then((resp) => resp.blob());
};

const downloadByGroup = (urls, files_per_group = 5) => {
  return Promise.map(
    urls,
    async (url) => {
      return await download(url);
    },
    { concurrency: files_per_group }
  );
};

export const downloadFolderAsZip = async (collection, titleOf = "catalog") => {
  const jszip = new JSZip();
  const folderRef = ref(storage, collection);
  const folder = await listAll(folderRef);
  const promises = folder.items
    .map(async (item) => {
      const file = await getMetadata(item);
      const fileRef = ref(storage, item.fullPath);
      const fileBlob = await getDownloadURL(fileRef).then((url) => {
        return fetch(url).then((response) => response.blob());
      });

      jszip.file(file.name, fileBlob);
    })
    .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
  await promises;
  const blob = await jszip.generateAsync({ type: "blob" });
  saveAs(blob, `${titleOf}.zip`);
  return;
};

export function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}
