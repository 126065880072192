import React from "react";
import About from "./About";
import About2 from "./About2";
import Work from "./Work";
import Contact from "./Contact";
import Footer from "./Footer";
import SignUp from "../Login/SignUpModal";
import { useState } from "react";
import { HomeView } from "./home/view/index";
function HomePage() {
  const [signup, setSignup] = useState(false);
  console.log(setSignup);
  return (
    <div>
      <HomeView signup={signup} setSignUp={setSignup} />
    </div>
  );
}

export default HomePage;
