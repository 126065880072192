import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { styled } from "@mui/material/styles";
import { auth } from "../../firebase.js";
import {
  DeleteForeverOutlined,
  DownloadDoneOutlined,
} from "@mui/icons-material";
import { getDataDoc, updateDocDatabase, uploadFileDatabse } from "../../utils";
import Button from "@mui/material/Button";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  TextField,
  Container,
  TextareaAutosize,
  FormLabel,
} from "@mui/material";
import Iconify from "../Manage/src/components/iconify";
import FormProvider, { RHFTextField } from "../Manage/src/components/hook-form";

// ----------------------------------------------------------------------
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AmplifyRegisterView() {
  const [errorMsg, setErrorMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [location, setLocation] = useState("");
  // const [logoLink, setLogoLink] = useState(null);
  // const [certLink, setCertLink] = useState("");
  const [firstName, setFirstName] = useState("");
  const [seePass, setSeePass] = useState(false);
  const [error, setError] = useState("");
  const [lastName, setLastName] = useState("");

  const [cui, setCui] = useState("");
  const [legalName, setLegalName] = useState("");
  const id = Date.now();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });
  const markViews = async () => {
    let data = await getDataDoc("views", "register");

    await updateDocDatabase("views", "register", {
      views: (data?.views || 0) + 1,
    });
  };
  useEffect(() => {
    markViews();
  }, []);
  const createUser = async () => {
    if (repeatPass !== password) {
      setError("Parolele nu coincid");
      setIsSubmitting(false);
      return;
    }
    if (
      !companyName ||
      !email ||
      !password ||
      !repeatPass ||
      !description ||
      !location ||
      !firstName ||
      !lastName ||
      !legalName ||
      !cui
    ) {
      setError("Ai câmpuri goale obligatorii");
      setIsSubmitting(false);
      return;
    }
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed up
        const user = userCredential.user;
        await updateDocDatabase("users", user.uid, {
          type: "unverified_stores",
        });
        await updateDocDatabase("unverified_stores", user.uid, {
          id: user.uid,
          email: user.email,
          companyName,
          description,
          location,
          firstName,
          lastName,
          cui,
          joined: new Date().getTime(),
          legalName,
        });
        setIsSubmitting(false);
        window.location.reload();
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
        setIsSubmitting(false);
        // ..
      });
  };
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: {},
  } = methods;

  const onSubmit = handleSubmit(async (data) => {});

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 3, position: "relative" }}>
      <Typography variant="h4">
        Înregistrează-te acum, și beneficiază de gratuitate
      </Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">
          {" "}
          Te-ai înscris deja? Așteptă pașii următori{" "}
        </Typography>

        {/* <Link variant="subtitle2">Sign in</Link> */}
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        mt: 2.5,
        textAlign: "center",
        typography: "caption",
        color: "text.secondary",
      }}
    >
      {"Înregistându-mă sunt de acord cu "}
      <Link
        underline="always"
        color="text.primary"
        onClick={() => {
          window.open("https://my-offer.ro/termeni.pdf", "_blank");
        }}
      >
        Termenii si condițille
      </Link>
      {" și "}
      <Link
        underline="always"
        color="text.primary"
        onClick={() =>
          window.open("https://my-offer.ro/confidentialitate.pdf", "_blank")
        }
      >
        Politica de confidențialitate
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <Typography>Datele Publice</Typography>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Denumire Comercială"
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
          fullWidth
          required
        />
      </Stack>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Descrie in cateva cuvinte activitatea firmei."
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          required
        />
      </Stack>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <GooglePlacesAutocomplete
          apiKey={"AIzaSyBZPy3LXlqPxY7xJ7NY5XpheUTR5z-ZVJw"}
          selectProps={{
            placeholder: "Locatia Unității primire clienți*",
            value: location,
            onChange: (e, t) => setLocation(e),
            styles: {
              width: "100%",

              input: (provided) => ({
                ...provided,
                color: "blue",
                backgroundColor: "transparent",
              }),
              option: (provided) => ({
                ...provided,
                zIndex: 100,
                color: "blue",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "blue",
                zIndex: 100,
              }),
            },
          }}
        />
      </Stack>

      <Typography>Datele Confidențiale</Typography>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Cod unic de identificare"
          onChange={(e) => setCui(e.target.value)}
          value={cui}
          fullWidth
          required
        />
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Numele Legal al Firmei"
          onChange={(e) => setLegalName(e.target.value)}
          value={legalName}
          fullWidth
          required
        />
      </Stack>

      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Nume"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          required
        />
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Prenume"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          required
        />
      </Stack>

      <RHFTextField
        name="email"
        label="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <RHFTextField
        name="password"
        label="Parola"
        type={seePass ? "text" : "password"}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  if (seePass === true) setSeePass(false);
                  else setSeePass(true);
                }}
                edge="end"
              >
                <Iconify
                  icon={seePass ? "solar:eye-bold" : "solar:eye-closed-bold"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <RHFTextField
        name="password"
        label="Repetă Parola"
        value={repeatPass}
        onChange={(e) => {
          setRepeatPass(e.target.value);
        }}
        type={seePass ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  if (seePass === true) setSeePass(false);
                  else setSeePass(true);
                }}
                edge="end"
              >
                <Iconify
                  icon={seePass ? "solar:eye-bold" : "solar:eye-closed-bold"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        variant="contained"
        loading={isSubmitting}
        onClick={async (e) => {
          e.preventDefault();
          setIsSubmitting(true);
          await createUser();
        }}
      >
        Pre-înscriere
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      {renderHead}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
      {!!error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      {renderTerms}
    </>
  );
}
