import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
// import {
//   getClase,
//   getAni,
//   getMaterii,
//   getProfesori,
//   getSettigs,
//   getAnunturi,
// } from "./actions";
const initialState = {
  user: null,
};

export const testSlice = createSlice({
  name: "database",
  initialState,
  reducers: {
    UPDATE_USER: (state, action) => ({
      ...state,
      user: action.payload,
    }),
  },
  extraReducers: (builder) => {
    // builder.addCase(todoAdded, (state, action) => {})
  },
  // [getMaterii.fulfilled]: (state, { payload }) => {
  //   if (payload?.payload?.array?.length > 0) {
  //     state.materii = [...payload?.payload?.array];
  //   }
  //   state.loading = false;
  // },
  // [getAnunturi.fulfilled]: (state, { payload }) => {
  //   if (payload?.payload?.array?.length > 0) {
  //     state.anunturi = [...payload?.payload?.array];
  //   }
  //   state.loading = false;
  // },
  // [getMaterii.pending]: (state, { payload }) => {
  //   state.loading = true;
  // },
});
export const store = configureStore({
  reducer: testSlice.reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
