import "./App.css";
import HomePage from "./Components/HomePage/HomePage";
import RegisterView from "./Components/HomePage/RegisterView";
import { auth } from "./firebase";
import { updateUser } from "./store";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import Manage from "./Components/Manage/src/Manage";
import LogedView from "./Components/HomePage/home/LogedView";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { testSlice } from "./store";
import { getDataDoc } from "./utils";
import ViewStore from "./Components/ViewStore";
import { onSnapshot, doc } from "firebase/firestore";
import Register from "./Components/HomePage/Register";
import { db } from "./firebase";
const { actions } = testSlice;
const { UPDATE_USER } = actions;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" exact element={<HomePage />}>
      <Route path="register" element={<Register />} />
      {/* ... etc. */}
    </Route>
  )
);

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user

        let data = await getDataDoc("users", user.uid);

        let dataStore = await getDataDoc(data?.type, user.uid);

        let finalData = await fetchMoreData({ ...user, ...data, ...dataStore });
        dispatch(UPDATE_USER(finalData));
        onSnapshot(
          doc(db, "users", user.uid),
          { includeMetadataChanges: true },
          async (doc) => {
            let dataStore = await getDataDoc(data?.type, user.uid);

            let finalData = await fetchMoreData({
              ...user,
              ...data,
              ...dataStore,
            });
            dispatch(UPDATE_USER(finalData));
          }
        );
        onSnapshot(
          doc(db, "stores", user.uid),
          { includeMetadataChanges: true },
          async (doc) => {
            let dataStore = await getDataDoc(data?.type, user.uid);

            let finalData = await fetchMoreData({
              ...user,
              ...data,
              ...dataStore,
            });
            dispatch(UPDATE_USER(finalData));
          }
        );
        onSnapshot(
          doc(db, "unverified_stores", user.uid),
          { includeMetadataChanges: true },
          async (doc) => {
            let dataStore = await getDataDoc(data?.type, user.uid);

            let finalData = await fetchMoreData({
              ...user,
              ...data,
              ...dataStore,
            });
            dispatch(UPDATE_USER(finalData));
          }
        );

        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);

  const fetchMoreData = async (store) => {
    const offersDetails = [];
    const news = [];
    for await (let off of store.offers || []) {
      let data = await getDataDoc("offers", off);
      offersDetails.push({ ...data, id: off });
    }
    for await (let off of store?.news || []) {
      let data = await getDataDoc("news", off);
      news.push({ ...data, id: off });
    }
    return {
      ...store,
      offers: offersDetails,
      news: news.sort((a, b) => (a.edited > b.edited ? 1 : -1)),
    };
  };

  useEffect(() => {
    if (user && pathname === "/" && user?.type === "unverified_stores")
      navigate("/register");
  }, [user]);

  return (
    <>
      {user?.type === "unverified_stores" && <LogedView />}
      {user?.type !== "unverified_stores" && user?.type !== "stores" && (
        <div className="not_logged">
          <Routes>
            <Route path="/" element={<HomePage />} />{" "}
            {/* 👈 Renders at /app/ */}
            <Route
              path="register"
              element={
                <RegisterView>
                  <Register />
                </RegisterView>
              }
            />
          </Routes>
        </div>
      )}
      {user?.type === "store" && <Manage />}
    </>
  );
}

export default App;
