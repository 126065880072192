import { m } from "framer-motion";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Logo from "../../Manage/src/components/logo/logo";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

// ----------------------------------------------------------------------

export default function NotFoundView() {
  return (
    <Container component="main">
      <Stack
        sx={{
          py: 12,
          m: "auto",
          maxWidth: 400,
          minHeight: "100vh",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <m.div>
          <Logo />
        </m.div>
        <m.div>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Bravo! Tocmai ai câștigat un an gratuit de MyOffer.
          </Typography>
        </m.div>

        <m.div>
          <Typography sx={{ color: "text.secondary" }}>
            Te vom contacta în momentul în care dăm startul celei mai inovatoare
            platforme de cupoane din România.
          </Typography>
        </m.div>
      </Stack>
    </Container>
  );
}
